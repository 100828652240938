<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">储值卡批量添加</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="submit()">生成套餐</el-button>
            </div>
        </div>
        <el-card class="el-main sceneCheckContent">
            <div class="a-flex-rfsc">
                <le-select-remote-search label="项目" v-model="incomeDistriId" :options="optionsincomeDistri" placeholder="请选择项目(可搜索)" />
                <le-select-remote-search 
                    label="站点"
                    :multiple="true" 
                    v-model="statioinIdList" 
                    :options="optionsStatioinIdList" 
                    placeholder="请选择站点(可搜索)" />
                <div class="a-flex-1 a-flex-rfec">
                    <el-button type="primary" @click="getTableData()">刷新模板</el-button>
                    <el-button type="primary" @click="addTableData()">添加套餐</el-button>
                </div>
            </div>
            <span class="a-c-error a-pb-10">*当前毛豆兑换比例为1:100，即￥1等于100毛豆。</span>
            <el-table 
                ref="monthCardbatchlist" 
                @row-dblclick="dbclick"
                :cell-class-name="tableCellClassName"
                :row-style="rowStyle"
                :data="tableData" 
                style="width: 100%;" 
                height="500">
                <el-table-column prop="name" label="名称" min-width="140" fixed="left">
                    <template slot-scope="scope">
                        <span class="point-edit" :class="scope.row.name_edit?'a-c-red':'a-c-blue'">{{ scope.row.name?scope.row.name:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="划线价" min-width="100">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.price_edit?'a-c-red':'a-c-blue'">￥{{ util.numFormat(row.price) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="promotionPrice" label="金额" min-width="100">
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.promotionPrice_edit?'a-c-red':'a-c-blue'">￥{{ util.numFormat(row.promotionPrice) }}</span>
                        <span>（</span>
                        <jd-md-price :number="row.promotionPrice?row.promotionPrice:0"></jd-md-price>
                        <span>）</span>
                    </template>
                </el-table-column>
                <el-table-column prop="giftAmount" label="赠送（毛豆）" min-width="100">
                    <template slot-scope="{ row }">
                        <!-- <span class="point-edit" :class="row.giftAmount_edit?'a-c-red':'a-c-blue'">{{ row.giftAmount }}</span> -->
                        <jd-md-price :class="row.giftAmount_edit?'a-c-red':'a-c-blue'" :number="row.giftAmount?row.giftAmount:0"></jd-md-price>
                    </template>
                </el-table-column>
                <el-table-column prop="validity" label="有限期（天）" min-width="100">
                    <template slot="header" slot-scope="scope">
                        <div class="a-flex-cfsfs">
                            <span class="a-fs-14">有限期（天）</span>
                            <span class="a-fs-12">-1为长期</span>
                        </div>
                    </template>
                    <template slot-scope="{ row }">
                        <span class="point-edit" :class="row.validity_edit?'a-c-red':'a-c-blue'">{{ row.validity?row.validity:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" prop="isCreate" label="是否创建" min-width="80">
                    <template slot-scope="{ row }">
                        <el-switch
                            v-model="row.isCreate"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" prop="status" label="状态" min-width="80">
                    <template slot-scope="{ row }">
                        <span v-if="row.status == 1" style="color: #67C23A"><i class="el-icon-success a-pr-4"></i>成功</span>
                        <span v-else-if="row.status == 2" style="color: #F56C6C"><i class="el-icon-error a-pr-4"></i>失败</span>
                        <span v-else style="color: #E6A23C"><i class="el-icon-question a-pr-4"></i>待生成</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height:17px"></div>
        </el-card>
        <edit-table-value-dialog ref="editDialog" @submitVal="submitVal"></edit-table-value-dialog>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
import EditTableValueDialog from './child/editTableValue-dialog.vue';
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex';
    export default {
        components:{
            EditTableValueDialog,
        },
        data () {
            return {
                util: util,
                tableData: [],
                incomeDistriId: '',//当前项目id
                optionsincomeDistri: {
                    url: this.$Config.apiUrl.incomeDistriList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        searchKey: "",
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//项目数据
                groupId: 3, // 固定查储值卡模板
                groupIdList: [],
                statioinIdList: [],// 当前选择站点id集合
                optionsStatioinIdList: {
                    url: this.$Config.apiUrl.monthStationList,
                    method: "post",
                    params: {
                        incomeDistriId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },//站点数据
                currentCell: null,//当前选中单元格
                defaultActivity: null,
                currentIndex: '',
                chargingExpireTypeNotice: true
            }
        },
        watch:{
            incomeDistriId :{
                handler (val) {
                    this.optionsStatioinIdList.params.incomeDistriId = val
                    this.statioinIdList = []
                }
            },
        },
        computed: {
            ...mapState(["company"]),
        },
        mounted () {
            this.getGroupList()
        }, 
        methods: {
            getGroupList () {
                this.$Axios._post({
					url: this.$Config.apiUrl.monthCardTemplateList,
					params: {
						groupName: "",
                        type: 1,//0--套餐卡模版 1--储值卡模板
					}
				}).then(({
					data
				}) => {
					this.groupIdList = data.map(item=>{
                        return {
                            ...item,
                            label: item.groupName,
                            value: item.groupId
                        }
                    })
                    this.groupId = this.groupIdList[0].value
                    this.getTableData()
				})
            },
            addTableData () {
                this.tableData.unshift({
                    name: "毛豆储值卡",//名称(月卡/季卡/年卡/时长卡/次卡)
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/wxapp/czv.png',//头像
                    type: '5',//月卡类型 1:充电卡 2:停车卡 3:停车充电卡
                    isTempCard: '6',//是否是临时卡 1:是 0:否
                    isSystemDefaultCard: '0',//是否是系统默认卡 1:是 0:否
                    validity: -1,//有效期(天)
                    price: '',//原价(划线价,对比价,单位:分)
                    promotionPrice: '',//促销价(实际支付价格,单位:分)
                    promotionDescription: "毛豆储值卡",//促销描述
                    quotaTimes: '0',//充电次数限制
                    quotaDuration: '0',//充电时长限制(单位:分)
                    parkTimes: '0',//停车限制次数 0:不限制
                    applicableDescription: "毛豆储值卡",//适用范围描述
                    activationMode: 0,//激活模式(0:立即激活,1:首次使用激活)
                    showMode: 1,//1:全部可见 2:充电可见 3:停车可见
                    cardNeedPost: '0',//卡片是否需要邮寄 1:需要 0:不需要
                    cardPrice: 0,//实体卡费用
                    enableTime: dayjs(),//开售时间
                    expireTime: dayjs().add(20, 'year'),//失效时间
                    quotaTimeType: '',//充电时间类型
                    parkTimeType: '',//停车时间限制
                    quotaLimitType: '',// 充电次数限制类型
                    parkLimitType: '',// 开门次数限制类型
                    bikeTypeIdList: [],//
                    giftAmount: '',//储值卡充值赠送金额
                    activity: null,//关联活动
                    activityList: null,//关联活动
                    payWay: '1',
                    maxPrice: '',//临停后付金额上限
                    chargingExpireType: '1',
                    isCreate: true,
                    status: 0,
                })
            },
            getTableData () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.monthCardTemplate,
                    params: {
                        groupId: this.groupId
                    },
                    method: "post",
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.tableData = res.result.data.map(item=>{
                            return {
                                ...JSON.parse(item.json),
                                isCreate: true,
                                status: 0,
                            }
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            },
            submit () {
                if(!this.statioinIdList.length){
                    this.$message.warning('请先选择项目站点')
                    return
                }
                let card = this.tableData.find(res=>{
                    return res.promotionPrice >= 200 || res.giftAmount / 100 / res.promotionPrice > 0.25
                })
                if(card && this.company.company.companyType == 2) {
                    this.$message.error('储值卡最大额度不得超过200元，且赠送金额不得超过售价的25%')
                    return false
                }
                this.$confirm('是否确认批量添加所选套餐？','温馨提示')
                .then(async _=>{
                    let arr = []
                    this.tableData.map((item,index)=>{
                        if(item.isCreate){
                            arr.push(item)
                        }
                    })
                    if(arr.length){
                        for (const item of arr) {
                            await this.submitMonthCard({
                                ...item,
                                statioinIdList: this.statioinIdList,
                                incomeDistriId: this.incomeDistriId,
                            }).then(res=>{
                                item.isCreate = false
                                item.status = 1
                            }).catch(err=>{
                                console.log(err);
                                item.isCreate = true
                                item.status = 2
                            })
                        }
                    }else{
                        this.$message.warning('请至少勾选一条套餐模板')
                    }
                    
                })
                .catch(err=>{
                    console.log(err);
                })
            },

            submitMonthCard (item) {
                return new Promise((resolve,reject)=>{
                    let reqData = {
                        ...item
                    }
                    reqData.statioinIdList = reqData.statioinIdList.map(item=>{
                        return {
                            code: item,
                            name: ''
                        }
                    })
                    this.$Axios._post({
                        url: this.$Config.apiUrl.addMonthCard,
                        method: "post",
                        params: {
                            validList: [reqData]
                        }
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            resolve()
                        } else {
                            this.$message.error(res.result.message)
                            reject()
                        }
                    })
                    .catch(err=>{
                        console.log(err);
                        reject()
                    })
                })
                
            },
            // 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
            tableCellClassName ({row, column, rowIndex, columnIndex}) {
                row.index=rowIndex;
                column.index=columnIndex;
            },
            rowStyle ({row, rowIndex}) {
                if(row.status == 1){
                    return {
                        'background': '#f0f9eb'
                    }
                }else if(row.status == 2){
                    return {
                        'background': 'rgb(254, 240, 240)'
                    }
                }else{
                    return {
                        'background': '#ffffff'
                    }
                }
            },
            // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
            dbclick (row,column) {
                let black = ['enableTimeText','expireTimeText','isTempCard','cardType','bikeTypeIdList','isCreate','status']
                if(black.indexOf(column.property) != -1){
                    return
                }
                this.currentCell = {
                    rowIndex: row.index,
                    prop: column.property
                }
                
                if(column.property == 'promotionPrice'){
                    this.$refs['editDialog'].dialogVisible = true
                    let promotionPrice = row['promotionPrice']?row['promotionPrice']:0
                    this.$refs['editDialog'].value1 = Number((promotionPrice/100).toFixed(2)) 
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'price'){
                    this.$refs['editDialog'].dialogVisible = true
                    let price = row['price']?row['price']:0
                    this.$refs['editDialog'].value1 = Number((price/100).toFixed(2)) 
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'cardPrice'){
                    this.$refs['editDialog'].dialogVisible = true
                    let cardPrice = row['cardPrice']?row['cardPrice']:0
                    this.$refs['editDialog'].value1 = Number((cardPrice/100).toFixed(2)) 
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'money'
                }else if(column.property == 'payWay'){
                    this.$refs['editPayWayDialog'].dialogVisible = true
                    let maxPrice = row['maxPrice']?row['maxPrice']:0
                    let payWay = row['payWay']?row['payWay']:'1'
                    this.$refs['editPayWayDialog'].form.maxPrice = Number((maxPrice/100).toFixed(2)) 
                    this.$refs['editPayWayDialog'].form.payWay = payWay
                }else if(column.property == 'chargingExpireType'){
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].valueType1 = 'radio'
                    this.$refs['editDialog'].valueDic1 = this.chargingExpireTypeDic
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }else{
                    this.$refs['editDialog'].dialogVisible = true
                    this.$refs['editDialog'].value1 = row[column.property]
                    this.$refs['editDialog'].value2 = ''
                    this.$refs['editDialog'].label1 = column.label
                    this.$refs['editDialog'].label2 = ''
                    this.$refs['editDialog'].title = '修改'+column.label
                    this.$refs['editDialog'].inputType = 'string'
                }
            },
            submitVal (datas) {
                if(this.currentCell.prop == 'promotionPrice'){
                    this.tableData[this.currentCell.rowIndex]['promotionPrice'] = datas.value1
                    this.tableData[this.currentCell.rowIndex]['price'] = datas.value2
                    this.$set(this.tableData[this.currentCell.rowIndex],'promotionPrice'+'_edit',true)

                }else if(this.currentCell.prop == 'chargingExpireType'){
                    if(this.chargingExpireTypeNotice){
                        this.$alert('资源类型修改后，对应卡组下的所有套餐的资源类型都会被改为对应类型，请仔细核对后提交。', '温馨提示', {
                            confirmButtonText: '我知道了',
                            showCancelButton: true,
                            cancelButtonText: '不再提示',
                            type: 'warning',
                            callback: action => {
                                if(action == 'cancel') {
                                    this.chargingExpireTypeNotice = false
                                }
                                this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                                this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                            }
                        });
                    }else{
                        this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                        this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                    }
                    
                }else{
                    this.tableData[this.currentCell.rowIndex][this.currentCell.prop] = datas.value1
                    this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
                }
            },
            submitPayWay (datas) {
                this.tableData[this.currentCell.rowIndex]['payWay'] = datas.payWay
                this.tableData[this.currentCell.rowIndex]['maxPrice'] = datas.maxPrice
                this.$set(this.tableData[this.currentCell.rowIndex],this.currentCell.prop+'_edit',true)
            },
            // 当input失去焦点的时候，隐藏input
            hideInput () {
                this.currentCell = null;
            },
            // 选择添加活动
            chooseActivity (index) {
                this.defaultActivity = this.tableData[index].activityList?this.tableData[index].activityList[0]:null
                this.$refs['chooseActivity'].dialogVisible = true
                this.currentIndex = index
            },
            getActivity (datas) {
                // this.$nextTick(()=>{
                //     this.$set(this.form,'activity',datas)
                // })
                this.$set(this.tableData[this.currentIndex],'activityList',[])
                this.$set(this.tableData[this.currentIndex].activityList,0,datas)
                console.log(this.tableData);
            },
            // 删除选择的活动
            removeActivity (index) {
                this.$confirm('是否确认删除？','温馨提示')
                .then(_=>{
                    this.tableData[this.currentIndex].activityList.splice(index,1)
                })
                .catch(_=>{})
            }
        }
    }
</script>

<style lang="scss" scoped>
.monthCard{
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__expand-column .cell {
        // display: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
}
/deep/ .sceneCheckContent{
    .el-table__column-filter-trigger{
        display: none;
    }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
    margin-right: -18px;
}
.point-edit{
    // cursor: url('../../assets/icon/edit-mouse.png'), default;
    cursor: pointer;
}
.scrollBarDiv{
    width: auto;
    position: fixed;
    left: 157px;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 10px 52px 16px;
    z-index: 2000;
    border-top: 1px solid #EBEEF5;
}
.a-pr-4{
    padding-right: 4px;
}
.el-table .wait-row {
    background: #ffffff;
}

.el-table .success-row {
    background: #f0f9eb;
}
.el-table .fail-row {
    background: rgb(253, 226, 226);
}
</style>